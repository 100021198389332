import {
  DistributionErrorName,
  DistributionErrorNameApi,
} from '@core/services/distribution/form/validation/distribution-error';
import { FfNgxControlErrorMessages } from "@fagforbundet/ngx-components";

export const DISTRIBUTION_ERROR_MESSAGES: FfNgxControlErrorMessages<
  DistributionErrorName | DistributionErrorNameApi
> = {
  messageTypeRequired: () => 'Du må velge minst ett meldingsformat',
  INVALID_SYNTAX: () => 'Ugyldig syntaks',
  RENDER_FAILED: () => 'Ugyldig variabel',
  emailRequired: () => 'Distribusjonen mangler e-post',
};
